var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MusicControls", {
    attrs: {
      trackName: _vm.trackName,
      isAudioStop: _vm.isAudioStop,
      isAudioPause: _vm.isAudioPause,
      isAudioPlay: _vm.isAudioPlay,
      isOverlay: _vm.isOverlay,
    },
    on: { onTogglePlay: _vm.togglePlay, onStop: _vm.onStop },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }