var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "music-controls" },
    [
      _c(
        "div",
        { staticClass: "music-controls__container" },
        [
          !_vm.isAudioStop && _vm.trackName
            ? [
                _c("div", { staticClass: "music-controls__track-status" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isAudioPause ? "Paused" : "Now playing") +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "music-controls__track-name",
                    class: { marquee: _vm.trackName.length > 15 },
                  },
                  [_c("div", [_vm._v('"' + _vm._s(_vm.trackName) + '"')])]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.isOverlay
        ? _c(
            "RtbControlButton",
            {
              staticStyle: { width: "35px" },
              attrs: { sm: "", disabled: _vm.isAudioStop },
              on: { click: _vm.onTogglePlay },
            },
            [
              !_vm.isAudioPlay
                ? _c("svg-icon", {
                    attrs: { name: "play-arrow-outlined", size: "small" },
                  })
                : _c("v-icon", { attrs: { dark: "" } }, [_vm._v("pause")]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "rtb-control-button",
                    _vm._g(
                      {
                        staticStyle: { width: "20px" },
                        attrs: { flat: "", sm: "" },
                        on: {
                          click: function ($event) {
                            _vm.loopStatus = !_vm.loopStatus
                          },
                        },
                      },
                      on
                    ),
                    [
                      !_vm.loopStatus
                        ? _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("repeat"),
                          ])
                        : _c("v-icon", { attrs: { color: "#6E69D1" } }, [
                            _vm._v("repeat_one"),
                          ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Controls the looping of your audio ")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }