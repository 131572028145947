











import Vue from "vue"
import { mapActions, mapGetters } from "vuex"

import MusicControls from "./MusicControls.vue"

export default Vue.extend({
  name: "GameMusicPlayer",
  components: { MusicControls },
  created() {
    if (this.isDIYGame && !(this.isHost || this.isModerator)) {
      this.$watch("currentAudioTrack", this.currentAudioTrackWatcher)
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost", "isModerator"]),
    ...mapGetters(["isDIYGame", "game"]),
    ...mapGetters("soundeffect", [
      "getAllAudios",
      "currentAudioTrack",
      "isAudioStop"
    ]),
    trackName() {
      return this.currentAudioTrack?.name
    },
    currentAudioTrack() {
      if (this.game) {
        if (
          this.game.audioTrack &&
          ["play", "pause"].includes(this.game.audioTrack.status)
        ) {
          return this.game.audioTrack
        }
      }
      return null
    },
    isOverlay() {
      return this.currentAudioTrack?.overlay
    },
    isAudioPlay() {
      return this.currentAudioTrack?.status === "play"
    },
    isAudioPause() {
      return this.currentAudioTrack?.status === "pause"
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameAudioTrack"]),
    currentAudioTrackWatcher(val, oldValue) {
      if (this.user.id !== val?.updatedBy?.id) {
        if (val && val.status) {
          let message = ""
          if (val.key !== oldValue.key) {
            message = `${val.updatedBy.firstname} changed music`
          } else if (val.status === "play") {
            message = `${val.updatedBy.firstname} started music`
          } else if (val.status === "pause") {
            message = `${val.updatedBy.firstname} paused music`
          } else if (val.status === "stop") {
            message = `${val.updatedBy.firstname} stopped music`
          }

          if (message !== "") {
            this.$toast(message, { autoHideDelay: 5000 })
          }
        }
      }
    },
    togglePlay() {
      let status

      if (this.isAudioPlay) {
        status = "pause"
      }

      if (this.isAudioPause) {
        status = "play"
      }

      if (status) {
        this.updateGameAudioTrack({
          status,
          name: this.currentAudioTrack?.name || "",
          source: this.currentAudioTrack?.source,
          key: this.currentAudioTrack?.key,
          volume: this.currentAudioTrack?.volume,
          overlay: this.currentAudioTrack?.overlay
        })
      }
    },
    onStop() {
      this.updateGameAudioTrack({
        key: null,
        status: "stop",
        overlay: this.currentAudioTrack?.overlay
      })
    }
  }
})
