export const GoItem = {
  EDIT_TEAMS: "Edit Teams",
  START_GAME: "Start Game",
  RETURN_GAME: "Return to Game",
  EDIT_ACTIVITIES: "Edit Activities",
  EDIT_ACTIVITIES_LEGACY: "Edit Activities (Legacy)",
  EDIT_GAME_SETTINGS: "Edit Game Settings",
  EDIT_EVENT_SETTINGS: "Edit Event Settings",
  END_GAME: "End, players to lobby",
  SYNC_SCORE: "Sync Scores with Breadcrumb"
}
