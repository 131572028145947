












































import Vue from "vue"
import { RtbControlButton } from "@/components/ui"
import { mapGetters, mapActions } from "vuex"

export default Vue.extend({
  name: "MusicControls",
  components: {
    RtbControlButton
  },
  computed: {
    ...mapGetters("soundeffect", ["getAudioLoopStatus"]),
    loopStatus: {
      get() {
        return this.getAudioLoopStatus
      },
      set(val) {
        this.toggleAudioLoop(val)
      }
    }
  },
  props: {
    trackName: {
      type: String,
      default: undefined
    },
    isAudioStop: {
      type: Boolean,
      default: undefined
    },
    isAudioPause: {
      type: Boolean,
      default: undefined
    },
    isAudioPlay: {
      type: Boolean,
      default: undefined
    },
    isOverlay: {
      type: Boolean,
      default: undefined
    }
  },
  methods: {
    ...mapActions("soundeffect", ["toggleAudioLoop"]),
    onTogglePlay(e) {
      e.target.blur()
      this.$emit("onTogglePlay")
    },
    onStop(e) {
      e.target.blur()
      this.$emit("onStop")
    }
  }
})
